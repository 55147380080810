import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import DeleEspagnol1 from '../assets/images/dele-espagnol/college-lycee-international-lafayette-dele-espagnol1.png';
import DeleEspagnol2 from '../assets/images/dele-espagnol/college-lycee-international-lafayette-dele-espagnol2.png';
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import '../../translation';
import { useTranslation } from 'react-i18next';

const DeleEspagnol = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const t = useTranslation('deleEspagnol').t;

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>DELE Espagnol | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth text'>
                    <div className='special-title'>
                        DELE Espagnol
                    </div>
                    <p className='text-center'><strong>{t('text1')}</strong></p><br/>
                    <p><em>{t('text2')}</em></p><br/><br/>
                    <div className='content-image text-center'>
                        <img width='300px' src={DeleEspagnol1} />
                    </div><br/><br/>
                    <p>{t('text3')}</p><br/>
                    <p>{t('text4')}</p><br/><br/>
                    <div className='content-image text-center'>
                        <img width='300px' src={DeleEspagnol2} />
                    </div><br/><br/>
                    <p><strong>{t('text5')}</strong></p>
                    <p>{t('text6')}</p>
                    <p>{t('text7')}</p>
                    <p>{t('text8')}</p>


                </div>
            </div>
            <Footer />
        </>

    )
}

export default DeleEspagnol;